a {
  cursor: pointer;
}
body {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.containerWrapper {
  max-width: 1400px;
  margin: 0 auto;
}
.flatpickr-monthSelect-month {
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin: 0.5px;
  justify-content: center;
  padding: 10px;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  width: 32%;
}

.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background-color: #569ff7;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-monthSelect-month.inRange,
.flatpickr-monthSelect-month.inRange.today,
.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month:focus {
  background: #e6e6e6;
  cursor: pointer;
  outline: 0;
  border-color: #e6e6e6;
}

.holiday {
  background: rgba(113, 192, 117, 0.2) !important;
}

.slick-dots {
  display: none;
}
