body {
  margin: 0;
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #d3d3d34d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  width: 95%;
}

a {
  text-decoration: none !important;
}

.page-home .page-container .home-main,
.page-home .page-container .home-main .home-content {
  min-height: 100vh;
}

@media (max-width: 770px) {
  .page-home .page-container .home-main .home-testimonies__content {
    width: 100svw !important;
    height: 200px !important;
    overflow: hidden;
  }
  .page-home .page-container .home-main .home-testimonies {
    height: 300px !important;
  }
  .page-home .page-container .home-main .home-testimonies__slide .bg {
    background-position: center !important;
  }
  .region-content-front-search {
    padding: 20px;
    font-size: 15px !important;
  }
  .region-content-front-search div {
    font-size: 15px;
  }
  .page-home {
    background-color: #fff;
  }
  .page-home .page-container .home-main,
  .page-home .page-container .home-main .home-content {
    min-height: max-content !important;
  }
  .footer-links {
    justify-content: center !important;
    height: 50px;
  }
  .navbar-header {
    display: flex;
    align-items: center;
    padding: 0 10px !important;
  }
  .burger-icon {
    cursor: pointer;
    width: 30px;
    margin-left: auto;
  }
  .navbar::before,
  .navbar::after {
    display: none !important;
  }
  #main-navbar {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
  }
  #main-navbar.active {
    opacity: 1;
    visibility: visible;
  }
  .main-header .navbar-secondary a {
    padding: 10px 20px !important;
    font-size: 14px !important;
  }
  .navbar-header .icon-wrapper {
    margin-left: auto;
  }
  #cmpt-find-myjob-container .home-links a {
    color: #fff;
    width: 100%;
  }
}
